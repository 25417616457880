import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import './basictooltip.css';

export default function BasicTooltip() {
  return (
    <Tooltip enterTouchDelay={0} className="basic-tooltip" title="Count of people using in the last minute">
      <IconButton>
        <HelpOutlineRoundedIcon />
      </IconButton>
    </Tooltip>
  );
}