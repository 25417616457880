import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import CurrentDataContainer from "./components/CurrentData";
import "./App.css";

const queryClient = new QueryClient();

const App = () => {
   const [isMaintenance, setIsMaintenance] = useState(false);

   return (
      isMaintenance ? (
      <h1 style={{ margin: "20px" }}>
         Maintenance ongoing - We will be back soon
      </h1>
      ) : 
      (
      <div className="main">
         <QueryClientProvider client={queryClient}>
            <div className="header">
               <div className="header-image">
                  <div className="header-content">
                     <div
                        style={{
                           background: "#FFFFFF",
                           display: "flex",
                           padding: "0 7px",
                           borderRadius: 50,
                           alignItems: "center",
                        }}
                     >
                        {/* <img
                           src="image/icon-share.png"
                           alt="share"
                           width="30px"
                           height="30px"
                        /> */}

                        <div className="live-icon">
                           <div></div>
                           <h4>Live</h4>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

           <CurrentDataContainer setIsMaintenance={setIsMaintenance} />
         </QueryClientProvider>
            </div>
   )
   );
};

export default App;
