import React from "react";
import Widget from "../Widget";
import './index.css'

// const zone = {
//   // zone: "Workshare Area",
//   id: 5,
//   icon: "/images/workshare.png",
//   title: "Workshare Area",
//   tooltip: "",
//   value: "Workshare Area",
//   areas: [
//     {
//       title: "Workshare",
//       value: "WORKSHARE",
//       image: "/images/workshare-area.jpg",
//     },
//   ],
// };

const Modal = ({
	allChartData,
  currentData,
	zone,
	closeModal,
	zoneActive,
	setZoneActive,
}) => {
	return (
		<div className="moii-widget">
			<div className="App">
				<Widget
					allChartData={allChartData}
					currentData={currentData}
					zone={zone}
					closeModal={closeModal}
					zoneActive={zoneActive}
					setZoneActive={setZoneActive}
				/>
			</div>
		</div>
	);
};

export default Modal;
