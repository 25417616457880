import React from "react";
import cx from "classnames";

import "./index.css";

export default function Zone({ title, onClick, active }) {
  if (title === "Pool Club Room" || title === "Pool Deck") {
    return <div className="zone-removed">&nbsp;</div>;
  } else {
    return (
      <div
        className={cx("zone-link", active && "zone-active")}
        onClick={onClick}
      >
        {title}
      </div>
    );
  }
}
