import React, { useEffect, useState } from "react";

import Zone from "../Zone";
// import zones, { amenityAreas } from "../../static/zones";
import "./index.css";
import Graph from "../Graph/Graph";
import Day from "../Day";
import BasicTooltip from "../Tooltip/BasicTooltip";
import { ReactComponent as FitnessIcon } from "../../assets/svg/Fitness Icon.svg";
import { ReactComponent as BusinessIcon } from "../../assets/svg/Business Icon.svg";
import { ReactComponent as EastLoungeIcon } from "../../assets/svg/East Lounge Icon.svg";
import { ReactComponent as PoolIcon } from "../../assets/svg/Pool Icon.svg";
import { ReactComponent as ScreeningIcon } from "../../assets/svg/Screening Icon.svg";
import BusyTime from "../static/busyTime.json";

const days = ["SUN","MON", "TUE", "WED", "THU", "FRI", "SAT"];
const icons = {
  "Fitness Center": FitnessIcon,
  "Pool Area": PoolIcon,
  "Screening Room West Lounge": ScreeningIcon,
  "Resident Lounge": EastLoungeIcon,
  "Workshare Area": BusinessIcon,
};

export default function Widget({ allChartData, currentData, zone, closeModal, zoneActive, setZoneActive }) {
	// const amenityData = amenityAreas.find(({ title }) => title === zone.zone);
	// console.log("zone", zone);

	const [dayActive, setDayActive] = useState(
		new Date(
			new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
		).getDay()
	);

	const Icon = icons["Fitness Center"];

	const handleCloseModal = () => {
		closeModal();
		setZoneActive((state) => 0);
		setDayActive(
			new Date(
				new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
			).getDay()
		);
	};
	// const busyTime = zone
	//   ? BusyTime[zone?.areas[zoneActive]?.title]?.split(",")
	//   : [];

	return (
		<>
			{zone && (
				<div className="widget-container">
					<div className="widget-overlay">
						{/* Image of the room above the card */}

						<div className="widget-body-top-right">
							<div className="widget-body-top-right-title">
								<div className="widget-mobile-header">
									<Icon width="30px" />
								</div>
								<div className="widget-body-top-title">{zone.title}</div>
								<BasicTooltip />
								<div
									className="close-icon"
									style={{
										position: "absolute",
										right: "10px",
										cursor: "pointer",
									}}
									onClick={handleCloseModal}
								>
									<img src="/image/close.svg" alt="close" />
								</div>
							</div>
							<div
								className="widget-mobile-header-image"
								style={{
									backgroundImage: `url('${zone?.areas[zoneActive]?.image}')`,
									backgroundSize: "cover",
									backgroundPosition: "center",
									backgroundRepeat: "no-repeat",
								}}
							></div>
						</div>

						<div className="widget-header">
							{days.map((day, dayIndex) => (
								<Day
									key={day}
									title={day}
									active={dayActive === dayIndex}
									onClick={() => setDayActive(dayIndex)}
								/>
							))}
						</div>
						<div className="widget-body">
							<div className="widget-body-top">
								<div className="widget-body-top-left">
									{/* <div>
                <img src={"https://source.unsplash.com/random"} alt="logo" />
              </div> */}

									<div className="widget-body-top-zones">
										{zone?.areas?.length > 1 &&
											zone?.areas?.map((zone, zoneIndex) => {
												return (
													<React.Fragment key={zone?.value}>
														{zoneIndex > 0 && <div className="separator"></div>}
														<Zone
															title={zone.title}
															onClick={() => setZoneActive(zoneIndex)}
															active={zoneActive === zoneIndex}
														/>
													</React.Fragment>
												);
											})}
									</div>
								</div>
							</div>

							<Graph
								allChartData={allChartData}
								currentData={currentData}
								zone={zone?.areas[zoneActive]?.value}
								day={dayActive}
							/>

							{/* {busyTime.length > 0 ? (
            <div className="widget-body-bottom">
              <div className="widget-body-bottom-left">
                <b>Most Occupied Hours:</b> {busyTime[new Date().getDay() * 2]}
              </div>
              <div className="widget-body-bottom-right">
                <b>Least Occupied Hours:</b>{" "}
                {busyTime[new Date().getDay() * 2 + 1]}
              </div>
            </div>
          ) : null} */}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
