import "./index.css";
import Modal from "../modal";
import { useState } from "react";
import { ReactComponent as Person } from "../../assets/svg/Person Icon.svg";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/loading.json";
import { css } from "@emotion/react";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const CurrentData = ({ allChartData, currentData, loading, dayMaxValue }) => {
  console.log({ currentData });

  const breakPointPercent = 90; // To find busy or not busy in percentage of maxValue

  // const Zones = [
  //   {
  //     id: 1,
  //     icon: "/images/pool-area.svg",
  //     title: "Pool Area",
  //     tooltip: "",
  //     value: "POOL AREA",
  //     areas: [
  //       {
  //         title: "Lap Pool",
  //         value: "POOL AREA",
  //         image: "/images/NSF-POOL.jpeg",
  //         thresholdValue: dayMaxValue["POOL AREA"],
  //       },
  //       // {
  //       // 	title: "Jacuzzi",
  //       // 	value: "POOL AREA - JACUZZI",
  //       // 	image: "/images/NSF-JACUZZI.jpeg",
  //       // 	thresholdValue: dayMaxValue["POOL AREA - JACUZZI"],
  //       // },
  //     ],
  //   },

  //   // {
  //   //   id: 2,
  //   //   icon: "/images/crosfit.svg",
  //   //   // title: "Crossfit Room",
  //   //   title: "Personal Training Room",
  //   //   tooltip: "",
  //   //   value: "CROSSFIT ROOM",
  //   //   // value: "PERSONAL TRAINING ROOM",
  //   //   areas: [
  //   //     {
  //   //       // title: "Crossfit Room",
  //   //       title: "Personal Training Room",
  //   //       value: "CROSSFIT ROOM",
  //   //       // value: "PERSONAL TRAINING ROOM",
  //   //       image: "/images/NSF-CROSSFITROOM.jpeg",
  //   //       thresholdValue: dayMaxValue["CROSSFIT ROOM"],
  //   //     },
  //   //   ],
  //   // },

  //   {
  //     id: 3,
  //     icon: "/images/spin-studio.svg",
  //     title: "Spin Studio",
  //     tooltip: "",
  //     value: "SPIN STUDIO",
  //     areas: [
  //       {
  //         title: "Spin Studio",
  //         value: "SPIN STUDIO",
  //         image: "/images/NSF-SPIN STUDIO.jpeg",
  //         thresholdValue: dayMaxValue["SPIN STUDIO"],
  //       },
  //     ],
  //   },

  //   {
  //     id: 4,
  //     icon: "/images/weight-room.svg",
  //     title: "Weight Room",
  //     tooltip: "",
  //     value: "WEIGHT ROOM",
  //     areas: [
  //       {
  //         title: "Weight Room",
  //         value: "WEIGHT ROOM",
  //         image: "/images/NSF_WEIGHT ROOM.jpeg",
  //         thresholdValue: dayMaxValue["WEIGHT ROOM"],
  //       },
  //     ],
  //   },

  //   {
  //     id: 5,
  //     icon: "/images/cardio-room.svg",
  //     title: "Cardio Room",
  //     tooltip: "",
  //     value: "CARDIO ROOM",

  //     areas: [
  //       {
  //         title: "Cardio Room",
  //         value: "CARDIO ROOM",
  //         image: "/images/NSF-CARDIO.jpeg",
  //         thresholdValue: dayMaxValue["CARDIO ROOM"],
  //       },
  //     ],
  //   },

  //   // {
  //   //    id: 6,
  //   //    icon: "/images/cardio-room.svg",
  //   //    title: "Work Share",
  //   //    tooltip: "",
  //   //    value: "WORKSHARE",

  //   //    areas: [
  //   //       {
  //   //          title: "Work Share",
  //   //          value: "WORKSHARE",
  //   //          image: "/images/NSF-CARDIO.jpeg",
  //   //          thresholdValue: dayMaxValue["WORKSHARE"],
  //   //       },
  //   //    ],
  //   // },

  //   {
  //     id: 7,
  //     icon: "/images/multi-weight.svg",
  //     // title: "Multipurpose Weight Room",
  //     title: "Personal training ",
  //     tooltip: "",
  //     value: "MULTIPURPOSE WEIGHT ROOM",
  //     // value: "LEG TRAINING ROOM",

  //     areas: [
  //       {
  //         // title: "Multipurpose Weight Room",
  //         title: "Personal training",
  //         value: "MULTIPURPOSE WEIGHT ROOM",
  //         // value: "LEG TRAINING ROOM",
  //         image: "/images/NSF-MULTIPURPOSE AREA.jpeg",
  //         thresholdValue: dayMaxValue["MULTIPURPOSE WEIGHT ROOM"],
  //       },
  //     ],
  //   },

  //   {
  //     id: 8,
  //     icon: "/images/aerobic.svg",
  //     title: "Aerobic Room",
  //     tooltip: "",
  //     value: "AEROBIC STUDIO",

  //     areas: [
  //       {
  //         title: "Aerobic Room",
  //         value: "AEROBIC STUDIO",
  //         image: "/images/NSF-AEROBIC_STUDIO.jpeg",
  //         thresholdValue: dayMaxValue["AEROBIC STUDIO"],
  //       },
  //     ],
  //   },
  // ];

  const Zones = [
    {
      id: 1,
      icon: "/images/pool-area.svg",
      title: "FITNESS",
      tooltip: "",
      value: "FITNESS",
      areas: [
        {
          title: "FITNESS",
          value: "FITNESS",
          image: "/rcgimages/fitness.jpg",
          thresholdValue: dayMaxValue["FITNESS"],
        },
        // {
        // 	title: "Jacuzzi",
        // 	value: "POOL AREA - JACUZZI",
        // 	image: "/images/NSF-JACUZZI.jpeg",
        // 	thresholdValue: dayMaxValue["POOL AREA - JACUZZI"],
        // },
      ],
    },

    {
      id: 2,
      icon: "/images/crosfit.svg",
      // title: "Crossfit Room",
      title: "GAMEROOM",
      tooltip: "",
      value: "GAMEROOM",
      // value: "GAMEROOM",
      areas: [
        {
          // title: "Crossfit Room",
          title: "GAMEROOM",
          value: "GAMEROOM",
          // value: "GAMEROOM",
          image: "/rcgimages/gameroom.jpg",
          thresholdValue: dayMaxValue["GAMEROOM"],
        },
      ],
    },

    {
      id: 3,
      icon: "/images/spin-studio.svg",
      title: "GROUP FITNESS",
      tooltip: "",
      value: "GROUP FITNESS",
      areas: [
        {
          title: "GROUP FITNESS",
          value: "GROUP FITNESS",
          image: "/rcgimages/fitness.jpg",
          thresholdValue: dayMaxValue["GROUP FITNESS"],
        },
      ],
    },

    {
      id: 4,
      icon: "/images/weight-room.svg",
      title: "LOUNGE",
      tooltip: "",
      value: "LOUNGE",
      areas: [
        {
          title: "LOUNGE",
          value: "LOUNGE",
          image: "/rcgimages/lounge.jpg",
          thresholdValue: dayMaxValue["LOUNGE"],
        },
      ],
    },




  ];

  console.log("currentData", currentData);
  const [showModal, setShowModal] = useState(false);
  const [zoneActive, setZoneActive] = useState(0);
  const [zone, setZone] = useState();

  const modalHandler = (zone) => {
    setZone(zone);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          {Zones.map((zone) => {
            // console.log("zone", zone);
            return (
              <div className={`card ${zone?.areas?.length > 1 ? "widthFull" : "widthHalf"}`} key={zone?.title} onClick={() => modalHandler(zone)}>
                <div className="card-wrapper">
                  <div className="card-width">
                    <div className="card-header">
                      <div className="head-info">
                        <div className="icon-wraper">
                          <img src={`${zone?.icon}`} alt="fitness" height="18px" width="18px" />
                        </div>
                        <h5>{zone?.title}</h5>
                      </div>
                      <img src="/image/arrow-right.svg" alt="arrow-right" />
                    </div>

                    <div className="widget-card">
                      {zone.areas.map((area, i) => {
                        console.log("area", area);
                        const cache = JSON.parse(sessionStorage.getItem(`current-${area?.value}`));

                        const value = currentData[area?.value]
                          // ? currentData[area?.value]
                          // : // if the last updated sessionStorage is <= 10mins
                          // (cache && new Date() - new Date(cache.timeStamp)) <= 600000
                          // ? cache?.value
                          // : undefined;

                        // console.log(
                        // 	"time diff: ",
                        // 	(cache && new Date() - new Date(cache.timeStamp))
                        // 	);
                        console.log("actual value: ", currentData[area?.value]);
                        console.log("changed value: ", value)
                        console.log("********************************")

                        return (
                          <div
                            key={area?.title}
                            className="area-box"
                            style={{
                              width: zone?.areas?.length > 1 ? "41%" : "100%",
                            }}
                            onClick={() => setZoneActive((state) => i)}
                          >
                            {zone?.areas?.length > 1 && <h5 style={{ marginLeft: "30px" }}>{area?.title}</h5>}
                            {loading ? (
                              <div className="main-loading">
                                <Lottie options={defaultOptions} height={30} width={30} />
                              </div>
                            ) : value === 'N/A' ? (
                              <div className="not-live">Not Live</div>
                            ) : (
                              <div>
                              <div className="live1">
                                <div className={`status ${value >= (area.thresholdValue * breakPointPercent) / 100 && value > 3 ? "busy" : "notBusy"}`}>
                                  {value >= (area.thresholdValue * breakPointPercent) / 100 && value > 3 ? "Busy" : "Not Busy"}
                                </div>
                               
                                {/* <div>
                                  <img
                                    src="images/bar-chart.png"
                                    alt="share"
                                    width="30px"
                                    height="30px"
                                  />
                                </div> */}
                              </div>
                              <div className="live">
                              <div>
                                  <span className="value" >{value}</span>
                                  <Person className="person" />
                                </div>
                                  <div>
                                  <img
                                    src="images/bar-chart.png"
                                    alt="share"
                                    width="30px"
                                    height="30px"
                                  />
                                </div>
                                  
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className={`modal-container ${showModal ? "active" : ""}`}>
        <Modal allChartData={allChartData} currentData={currentData} zone={zone} closeModal={closeModal} zoneActive={zoneActive} setZoneActive={setZoneActive} />
      </div>
    </>
  );
};

export default CurrentData;
