/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { css } from "@emotion/react";
import "./index.css";
import { ReactComponent as Person } from "../../assets/svg/Person Icon.svg";
import axios from "axios";
// import "moment-timezone";

const apiUrl = "https://moii-api-analytics.ue.r.appspot.com/v2/getChartData";

const baseEndPoint = "https://moii-api-analytics.ue.r.appspot.com/execApp/v1/getExecutive_hourwise"

const url = "https://moii-api-analytics.ue.r.appspot.com/live-app/v1/current-day-occupancy"


// const apiUrl = "https://moii-api-analytics.ue.r.appspot.com/live-app/v1/current-day-occupancy"
const labels = ["5a", "8a", "11a", "2p", "5p", "8p", "11p"];
const activeProperty = "LEFRAK_NEWPORT_FITNESS";

const dayList = {
	"0" : {
	  day : 0,
	  value : 'Sun'
	},
	"1" : {
	  day : 1,
	  value : 'Mon'
	},
	"2" : {
	  day : 2,
	  value : 'Tue'
	},
	"3" : {
	  day : 3,
	  value : 'Wed'
	},
	"4" : {
	  day : 4,
	  value : 'Thu'
	},
	"5" : {
	  day : 5,
	  value : 'Fri'
	},
	"6" : {
	  day : 6,
	  value : 'Sat'
	}
  }

export default function Graph({ allChartData, currentData, zone, day }) {
	//  console.log("zone graph ", zone);
	const [currentHour, setCurrentHour] = useState(0);
	const [selectedHour, setSelectedHour] = useState(currentHour);
	const [currentHourValue, setCurrentHourValue] = useState()

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	let dayMaxValue = 0;
	const breakPointPercent = 90; // To find busy or not busy in percentage of maxValue

	const today = new Date(
		new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
	).getDay();

	// console.log("currentData", currentData, zone)

	const fetchData = async () => {
		try {
			setLoading(true);

			const res = await axios.get(
				// `${apiUrl}?ins_id=${activeProperty}&app=RESIDENT_APP&group_id=${zone}&day=${day}`
				`${baseEndPoint}?ins_id=${"RCG_LVL_NORTH"}&group_id=${zone}`
				// `${url}?client_name=${"LEFRAK_NEWPORT_FITNESS"}&customer_name=${activeProperty}&amenity_name=${zone}`

				// `${apiUrl}?customer_name=${activeProperty}&client_name="LEFRAK_PRODUCTION"&amentiy_name=${zone}`

			);

			const url1= "https://moii-api-analytics.ue.r.appspot.com/live-app/v1/getLiveFeed"

			const res2 = await axios.get(
				// `${apiUrl}?ins_id=${activeProperty}&app=RESIDENT_APP&group_id=${zone}&day=${day}`
				`${url1}?client_name=${"RCG_PRODUCTION"}&customer_name=${"RCG_LVL_NORTH"}&amenity_name=${zone}`
				// `${url}?client_name=${"LEFRAK_NEWPORT_FITNESS"}&customer_name=${activeProperty}&amenity_name=${zone}`

				// `${apiUrl}?customer_name=${activeProperty}&client_name="LEFRAK_PRODUCTION"&amentiy_name=${zone}`

			);
			    //   console.log("json", res2)

				  if(res2){
					setSelectedHour(res2.data.hour);
					setCurrentHour(res2.data.hour)
				  }


			// const url= "https://moii-api-analytics.ue.r.appspot.com/live-app/v1/getLiveFeed"
			// const res1 = await axios.get(
			// 	// `${apiUrl}?ins_id=${activeProperty}&app=RESIDENT_APP&group_id=${zone}&day=${day}`
			// 	`${url}??client_name=${"LEFRAK_PRODUCTION"}&customer_name=${"LEFRAK_NEWPORT_FITNESS"}&amenity_name=${zone}`
			// 	// `${url}?client_name=${"LEFRAK_NEWPORT_FITNESS"}&customer_name=${activeProperty}&amenity_name=${zone}`

			// 	// `${apiUrl}?customer_name=${activeProperty}&client_name="LEFRAK_PRODUCTION"&amentiy_name=${zone}`

			// );

		// 	const res1 =  fetch("https://moii-api-analytics.ue.r.appspot.com/live-app/v1/getLiveFeed", {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     "customer_name":activeProperty,
        //     "client_name":"LEFRAK_PRODUCTION",
		// 	"amentiy_name":zone
        //   })
        // });
    //   const json1 = await res1.json();

    //   console.log("json", json1)

	// res?.data?.response[dayList[day].value]
	const daySelected = dayList[day].value

	console.log("ressssss", res, res.data.response[daySelected], dayList[day].value)

			if (res.data.response[daySelected].length > 0) {
				console.log("res", res?.data?.response, res?.data?.response[dayList[day].value]);
				
				// setCurrentHour(currentData[zone]);


				setData((state) => res.data.response[daySelected]);
				// res.data.response[daySelected].forEach((item) => {
				// 	if (item?.current) {
				// 		delete item.current
				// 		setSelectedHour(item.hour);
				// 		setCurrentHour(item.hour);
				// 	}
				// });
			}
		} catch (err) {
			console.log("errrr", err);
			setData((state) =>
				Array(24)
					.fill(0)
					.filter((_, i) => ({ hour: i, value: 0 }))
			);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
		setCurrentHourValue(currentData[zone])
		// setSelectedHour(currentHour);
	}, [day, zone, allChartData]);

	//  if (isLoading) {
	//     return (
	//        <div className="graph-loading">
	//           <img src="/loading.gif" width={40} />
	//        </div>
	//     );
	//  }

	// console.log("data", data);
	const chartData = data
		.filter((each) => each.hour >= 5 && each.hour <= 23)
		.sort((a, b) => a.hour - b.hour);

		// console.log("chartData", chartData)

	// logic for Dynamic y-axis
	let maxValueForY = 0;
	let leftLabels = [];

	if (chartData.length === 0) {
		maxValueForY = 3;
		leftLabels = [3, 2, 1, 0];
	} else {
		
		chartData.forEach((each) => {
			console.log("each", each)
			if(each.hour === selectedHour) maxValueForY =  Math.max(maxValueForY, Math.round(currentHourValue), Math.round(each.avg));
			// if (each.current) maxValueForY = Math.max(maxValueForY, Math.round(each.current), Math.round(each.avg));
			else maxValueForY = Math.max(maxValueForY, Math.round(each.avg));

			dayMaxValue = Math.max(dayMaxValue, Math.round(each.avg));
		});

		if (maxValueForY === 0) maxValueForY = 3;

		if (maxValueForY % 3 !== 0) {
			for (let i = 0; i < 3; i++) {
				if (++maxValueForY % 3 === 0) break;
			}
		}
		for (let j = maxValueForY; j >= 0; j = j - maxValueForY / 3)
			leftLabels.push(j);

		console.log("maxValueForY", maxValueForY)
	}

	// console.log({ selectedHour, chartData, zone });

	return loading ? (
		<div className="graph-loading">
			<img src="/loading.gif" width={40} />
		</div>
	) : (
		<div className="graph-container">
			<div className="left-labels">
				{leftLabels.map((leftLabel) => (
					<div className="left-label-line" key={leftLabel}>
						<span className="left-label">{leftLabel}</span>
						{/* draw a line from the left label to the left label */}
						<span className="left-label-horizontal-line" />
					</div>
				))}
			</div>
			<div className="main-graph-wrapper">
				<div className="main-graph">
					{chartData.map((each, i) => {
						// To match the current value with the session storage
						// console.log("chart data", chartData)
						let currentValue; 
						const cache = JSON.parse(
							sessionStorage.getItem(`current-${zone}`)
						);
						// console.log("cache", cache)
						// if(today === day && cache && cache.hour === each.hour){
						// 	currentValue = currentData[zone]
						// 		? currentData[zone]
						// 		: // if the last updated sessionStorage is <= 10mins
						// 		(cache && new Date() - new Date (cache.timeStamp)) <= 600000
						// 		? cache.value
						// 		: undefined;
						// }

						if(each.hour === currentHour){
							console.log("each.hour", each.hour, currentHour, currentHourValue)
							currentValue = currentHourValue
						}

						console.log("current value", currentHourValue, currentValue)

						// 	if(today === day){
						// 	currentValue = currentData[zone]
						// 		? currentData[zone]
						// 		// : // if the last updated sessionStorage is <= 10mins
						// 		// (cache && new Date() - new Date(cache.timeStamp)) <= 600000
						// 		// ? cache.value
						// 		: undefined;
						// }

						let heightPercent =
							maxValueForY === 0
								? 0
								: parseInt(
										(parseInt(currentValue ?? each.avg, 10) * 100) /
											maxValueForY,
										10
								  );
						if (heightPercent === 0) {
							heightPercent = 1;
						}
						// Limit the height to 100%
						// if (heightPercent > 100) {
						// 	heightPercent = 100;
						// }
						const height =
							parseInt(each.hour, 10) === currentHour &&
							day === today &&
							heightPercent === 0
								? 5
								: heightPercent;
						let historicHeight =
							maxValueForY === 0
								? 0
								: parseInt(
										(parseInt(each.avg, 10) * 100) / maxValueForY,
										10
								  );
						historicHeight = historicHeight > 100 ? 100 : historicHeight;
						// const current = each.current
						// 	? parseInt(each.current, 10)
						// 	: parseInt(each.avg, 10);

						let current ;

						if(each.hour === currentHour){
							 current = currentHourValue
						}else{
							current = each.avg
						}

						console.log("")
						let status;
						if (dayMaxValue > 3) {
							status =
								current >= (dayMaxValue * breakPointPercent) / 100
									? "Busy"
									: current < (dayMaxValue * breakPointPercent) / 100
									? "Not Busy"
									: "Mod Busy";
						} else {
							status = "Not Busy"; // if dayMaxValue <= 3
						}

						const color =
							status === "Busy"
								? "#d92e00"
								: status === "Mod Busy"
								? "#c59b16"
								: "#40b54b";
						const liveIcon =
							status === "Busy"
								? "Live Icon Red"
								: status === "Mod Busy"
								? "Live Icon Orange"
								: "Live Icon Green";

						let tooltipLeft = -112;
						if (parseInt(each.hour, 10) > 21) {
							tooltipLeft = -50 - (parseInt(each.hour, 10) - 21) * 30;
						}
						if (parseInt(each.hour, 10) < 9) {
							tooltipLeft = -30 - (parseInt(each.hour, 10) - 5) * 20;
						}
						if (
							currentHour === parseInt(each.hour) &&
							day === today &&
							parseInt(each.hour, 10) > 21
						) {
							tooltipLeft = -150 - (parseInt(each.hour, 10) - 21) * 30;
						}

						if (
							!(today === day && selectedHour === each.hour) ||
							each.hour > currentHour
						) {
							status = `Usually ${status}`;
							if (parseInt(each.hour, 10) > 21) {
								tooltipLeft = -100 - (parseInt(each.hour, 10) - 21) * 30;
							}
						}

						const barHeight = Math.ceil(height / 10) * 10;
						// const barHeight = height
						const remainderOfBar = 110 - barHeight;
						let toolTipHeight = barHeight + remainderOfBar;

						// Manually Adjust tooltips for mobile until we can figure out how to make them responsive
						//TODO: Find a less hacky way to do this
						const getTooltipHeight = () => {
							if (height >= 100) {
								toolTipHeight = 30;
								return toolTipHeight;
							} else if (height < 2) {
								toolTipHeight = 145;
								return toolTipHeight;
							} else if (height < 19 && height > 2) {
								toolTipHeight = 130;
								return toolTipHeight;
							} else if (height < 29 && height > 18) {
								toolTipHeight = 115;
								return toolTipHeight;
							} else if (height < 39 && height > 28) {
								toolTipHeight = 105;
								return toolTipHeight;
							} else if (height < 49 && height > 38) {
								toolTipHeight = 95;
								return toolTipHeight;
							} else if (height < 59 && height > 48) {
								toolTipHeight = 80;
								return toolTipHeight;
							} else if (height < 69 && height > 58) {
								toolTipHeight = 70;
								return toolTipHeight;
							} else if (height < 79 && height > 68) {
								toolTipHeight = 55;
								return toolTipHeight;
							} else if (height < 89 && height > 78) {
								toolTipHeight = 40;
								return toolTipHeight;
							} else if (height < 99 && height > 88) {
								toolTipHeight = 40;
								return toolTipHeight;
							} else if (
								height >= 100 &&
								(zone === "Weight Room" || zone === "Pool Deck")
							) {
								toolTipHeight = 30;
								return toolTipHeight;
							} else if (
								height < 100 &&
								(zone === "Weight Room" || zone === "Pool Deck")
							) {
								toolTipHeight = toolTipHeight - 20;
								return toolTipHeight;
							} else {
								return toolTipHeight;
							}
						};

						const realHeight = getTooltipHeight();

						return (
							<React.Fragment key={each.hour}>
								<div
									key={each.hour}
									className="graph-item"
									style={{
										height: height + "%",
										backgroundColor:
											parseInt(each.hour, 10) === selectedHour && day === today
												? color
												: "#39499e",
									}}
									onClick={() => setSelectedHour(parseInt(each.hour, 10))}
								>
									{parseInt(each.hour, 10) === selectedHour ? (
										<>
											<div
												className="tooltip-line"
												css={css`
													height: ${((90 - height) * 240) / 100 + 70}px;
													@media screen and (max-width: 500px) {
														height: ${realHeight}px;
													}
												`}
											/>
											<div
												className="graph-tooltip"
												css={css`
													left: ${tooltipLeft}px;
													top: -${((90 - height) * 240) / 100 + 70}px;

													@media screen and (max-width: 500px) {
														top: -${realHeight}px;
														left: ${tooltipLeft +
														(selectedHour > 22 ? 30 : 0) +
														(selectedHour > 5 && selectedHour < 11 ? 50 : 0)}px;
													}
												`}
												style={{
													color: color,
												}}
											>
												{currentHour === parseInt(each.hour) &&
													day === today && (
														<>
															{/* Person icon */}
															<img
																alt={liveIcon}
																src={require(`../../assets/png/${liveIcon}.png`)}
															/>
															<b>LIVE :&nbsp;</b>
														</>
													)}
												<span className="graph-tooltip-text">{status}</span>
												<h3>
													{`${parseInt(
														currentValue ?? each.avg,
														10
													)}`}
												</h3>
												<Person
													css={css`
														& path {
															fill: ${color};
														}
													`}
												/>
											</div>
										</>
									) : (
										<>
											{/* Tool tip for {each.hour} that isn't clicked  */}
											<div
												className="graph-tooltip-unclicked"
												css={css`
													left: ${tooltipLeft}px;
													top: -${((20 - height) * 120) / 100 + 70}px;
													@media screen and (max-width: 500px) {
														top: -${realHeight}px;
														left: ${tooltipLeft +
														(selectedHour > 22 ? 30 : 0) +
														(selectedHour > 5 && selectedHour < 11 ? 50 : 0)}px;
													}
												`}
												style={{ color: color }}
											>
												{/* if each.value is greater than 10 then display each.value */}
												{/* {parseInt(each.avg) <= 10 ? null : null} */}
											</div>
										</>
									)}
								</div>
								{/* Shadow bars for {each.hour} */}
								{currentHour === parseInt(each.hour, 10) &&
									selectedHour === currentHour &&
									day === today &&
									historicHeight > height && (
										<div
											key={each.hour + "grey"}
											className="graph-item-historic"
											css={css`
												height: ${historicHeight}%;
												left: calc(
													30px +
														${((parseInt(each.hour, 10) - 5) / 19) * 100 + "%"} -
														${((parseInt(each.hour, 10) - 5) / 20) * 56 + "px"}
												);
											`}
										/>
									)}
							</React.Fragment>
						);
					})}
				</div>
				{/* X axis labels for the bar chart */}
				<div className="graph-label">
					{labels.map((label, index) => (
						<div key={label} className="graph-label-item">
							<div
								className="graph-label-item-tick"
								// css={css`
								// 	left: ${index%3 === 2 ? 6 : 2 + index*2}px;
								// `}
							/>
							<div>{label}</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
