import React from "react";
import cx from "classnames";

import "./index.css";

export default function Day({ title, onClick, active }) {
  return (
    <div className={cx("day-link", active && "day-active")} onClick={onClick}>
      {title}
    </div>
  );
}
