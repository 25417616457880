import React, { useEffect, useState } from "react";
import axios from "axios";

import CurrentData from "./CurrentData";

// const amenitites = [
//   "POOL AREA",
//   // "POOL AREA - LAP POOL",
//   // "POOL AREA - JACUZZI",
//   //   "CROSSFIT ROOM",
//   "SPIN STUDIO",
//   "WEIGHT ROOM",
//   "CARDIO ROOM",
//   "WORKSHARE",
//   "MULTIPURPOSE WEIGHT ROOM",
//   "AEROBIC STUDIO",
// ];

const amenitites = [
  "FITNESS",
   "GAMEROOM",
    "GROUP FITNESS",
     "LOUNGE"
]

const dayList = {
  "0" : {
    day : 0,
    value : 'Sun'
  },
  "1" : {
    day : 1,
    value : 'Mon'
  },
  "2" : {
    day : 2,
    value : 'Tue'
  },
  "3" : {
    day : 3,
    value : 'Wed'
  },
  "4" : {
    day : 4,
    value : 'Thu'
  },
  "5" : {
    day : 5,
    value : 'Fri'
  },
  "6" : {
    day : 6,
    value : 'Sat'
  }
}

const refreshRate = 60000;
// const refreshRate =  10000

// const baseEndPoint = "https://moii-api-analytics.ue.r.appspot.com/v2/getChartData";
// const baseEndPoint = "https://data-labelling-platform.uc.r.appspot.com/v2/getChartData";

const baseEndPoint = "https://moii-api-analytics.ue.r.appspot.com/execApp/v1/getExecutive_hourwise"

const liveApiEndPoint = "https://moii-api-analytics.ue.r.appspot.com/live-app/v1/getLiveFeed"

const day = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" })).getDay();

const CurrentDataContainer = ({ setIsMaintenance }) => {
  const [loading, setLoading] = useState(false);
  const [allChartData, setAllChartData] = useState({});
  const [currentData, setCurrentData] = useState({});
  const [dayMaxValue, setDayMaxValue] = useState({});
  // const [lastUpdated, setLastUpdated] = useState(0);
  // const [resData, setResData] = useState([]);
  const [liveData, setLiveData] = useState({});

  const [checkNotLive, setCheckNotLive] = useState(false)

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, refreshRate);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchLiveData();
    // setIsMaintenance(true)
  }, [liveData])

  const fetchData = async () => {
    try {
      setLoading(true);

      const liveApiArray = [];
      console.log("inside fetch data")

      for (let amenity of amenitites) {
        // apiArray.push(`${baseEndPoint}?ins_id=${"LEFRAK_NEWPORT_FITNESS"}&app=RESIDENT_APP&group_id=${amenity}&day=${day}`);
        liveApiArray.push(`${liveApiEndPoint}?client_name=${"RCG_PRODUCTION"}&customer_name=${"RCG_LVL_NORTH"}&amenity_name=${amenity}`);

      }

      const response1 = await Promise.all(liveApiArray.map(async (api) => await axios.get(api)));

      console.log("RESPONSE1", response1)

      setLiveData(response1)

      let localCurrentData = {
        // "POOL AREA" : null,
        // "SPIN STUDIO" : null,
        // "WEIGHT ROOM" : null,
        // "CARDIO ROOM" : null,
        // "MULTIPURPOSE WEIGHT ROOM" : null,
        // "AEROBIC STUDIO" : null,

        "FITNESS" : null,
        "GAMEROOM" : null,
         "GROUP FITNESS": null,
          "LOUNGE" : null
      };

      let i = 0;

      for (let amenity of amenitites) {
  
        let currentItem;
        let max = 0;
        console.log("inside loop", response1[i], liveData)
        response1[i].forEach((item) => {
          // if (item?.current !== undefined) {
          //   currentItem = item;
          // }
          console.log("item", item, liveData)
          if(item?.hour === liveData[i].data.hour){
            max = Math.max(liveData[i].data.current, item?.avg);
            currentItem = liveData[i].data
          }
        });
  
        // localCurrentData[amenity] = currentItem?.current;
  
        // localCurrentData[amenity] = currentData[i].data.current;

        console.log("localCurrentData", localCurrentData, amenity)
        localCurrentData[amenity] = liveData[i].data.current
     
        dayMaxValue[amenity] = max;
  
        // To store all the current values in the session storage
        if (currentItem?.current >= 0) {
          sessionStorage.setItem(
            `current-${amenity}`,
            JSON.stringify({
              hour: currentItem.hour,
              // value: currentItem.current,
              value: 1,
              timeStamp: new Date(),
            })
          );
        }
        i++;
      }
      
      setCurrentData((state) => localCurrentData);

    } catch (err) {
      console.log("errrr", err);
      if (err.response?.status >= 500) setIsMaintenance(true);
      setCurrentData([]);
    } finally {
      setLoading(false);
      // fetchLiveData();

      // setLastUpdated(0);
    }
  };

  // console.log("resData", resData);

  // const fetchLiveDta =  async () => {

  // }

  const fetchLiveData =  async () => {

    const apiArray = [];

    for (let amenity of amenitites) {
      // apiArray.push(`${baseEndPoint}?ins_id=${"LEFRAK_NEWPORT_FITNESS"}&app=RESIDENT_APP&group_id=${amenity}&day=${day}`);
      apiArray.push(`${baseEndPoint}?ins_id=${"RCG_LVL_NORTH"}&group_id=${amenity}`);

    }

    const response = await Promise.all(apiArray.map(async (api) => await axios.get(api)));

    // console.log("day", day, response)
    const data = response.map((res) => {
      // console.log("response", res?.data?.response[dayList][day])
      // console.log("day", day, dayList[day].value, res?.data?.response[dayList[day].value])
      return res?.data?.response[dayList[day].value]
    });



    // const liveData = response1.map((res) => res?.data?.response);
    
    console.log("data", data)
   

    if (data) {
      // const apiRes = {};
      // let i=0;
      // for(let key in amenitites){
      //    apiRes[amenitites[key]] = data[i];
      //    i++;
      // }
      // console.log('apiRes', apiRes);
      const [localChartData, localCurrentData, tempDayMaxValue] = charDataFormat(data);
      setAllChartData((state) => localChartData);
      console.log("localChartData", localChartData);
      setCurrentData((state) => localCurrentData);
      setDayMaxValue((state) => tempDayMaxValue);
      // setResData(data);
      // setIsMaintenance(false);
    }

  };



  const charDataFormat = (resData) => {
    let localCurrentData = {
      // "POOL AREA" : null,
      // "SPIN STUDIO" : null,
      // "WEIGHT ROOM" : null,
      // "CARDIO ROOM" : null,
      // "MULTIPURPOSE WEIGHT ROOM" : null,
      // "AEROBIC STUDIO" : null,


      "FITNESS" : null,
      "GAMEROOM" : null,
       "GROUP FITNESS": null,
        "LOUNGE" : null
    };
    let dayMaxValue = {};
    let localChartData = {};
    let i = 0;

    for (let amenity of amenitites) {
      localChartData[amenity] = resData[i];

      let currentItem;
      let max = 0;
      resData[i].forEach((item) => {
        // if (item?.current !== undefined) {
        //   currentItem = item;
        // }
        if(item?.hour === liveData[i].data.hour){
          max = Math.max(liveData[i].data.current, item?.avg);
          currentItem = liveData[i].data
        }else{
          max = Math.max(max, item?.avg);
        }
      });

      // localCurrentData[amenity] = currentItem?.current;

      // localCurrentData[amenity] = currentData[i].data.current;
      localCurrentData[amenity] = liveData[i].data.current
      // console.log("current data i", currentData[i].data.current )
      // if(currentData[i].data.current !== 'N/A' && currentData[i].data.current !== null ){
      //   console.log("in loop not N/a")
      //   localCurrentData[amenity] = currentData[i].data.current;
      // }
      // else{
      //   localChartData[amenity] = 'N/A'

      // }

      // localCurrentData[amenity] = 4;
      dayMaxValue[amenity] = max;

      // To store all the current values in the session storage
      if (currentItem?.current >= 0) {
        sessionStorage.setItem(
          `current-${amenity}`,
          JSON.stringify({
            hour: currentItem.hour,
            // value: currentItem.current,
            value: 1,
            timeStamp: new Date(),
          })
        );
      }
      i++;
    }

    // console.log({ dayMaxValue });
    return [localChartData, localCurrentData, dayMaxValue];
  };

  return <CurrentData allChartData={allChartData} currentData={currentData} dayMaxValue={dayMaxValue} loading={loading} />;
};

export default CurrentDataContainer;
